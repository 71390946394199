import React, { Fragment, useState, useEffect } from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Listbox, Transition, Tab } from "@headlessui/react";

import { mapEdgesToNodes } from "../lib/helpers";
import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import CallToAction from "../components/Repeating/CTA";

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Global.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Global.jpg" }
    ) {
      publicURL
    }
    portfolio: allSanityPortfolio(sort: { fields: [order], order: ASC }) {
      edges {
        node {
          slug {
            current
          }
          title
          logo {
            asset {
              gatsbyImageData(layout: CONSTRAINED, width: 136)
            }
          }
          industry {
            title
          }
          dateInvested
          status
          equityDebt
        }
      }
    }
  }
`;

const Page = ({ data }) => {
  const investmentNodes = [
    { title: "Show All Investments" },
    { title: "Debt Investments" },
    { title: "Equity Investments" },
  ];

  const portfolioNodes = (data || {}).portfolio
    ? mapEdgesToNodes(data.portfolio)
    : [];

  const [selectedInvestment, setSelectedInvestment] = useState(
    investmentNodes[0]
  );
  const [selectedIndex, setSelectedIndex] = useState(0);

  const [filteredArray, setFilteredArray] = useState(portfolioNodes);

  useEffect(() => {
    // Conditional filters
    if (selectedInvestment.title !== investmentNodes[0].title) {
      setFilteredArray(() => {
        const newArray = portfolioNodes.filter(
          (items) => items.equityDebt === selectedInvestment.title
        );
        return newArray;
      });
    } else if (selectedIndex !== 0) {
      if (selectedIndex === 1) {
        setFilteredArray(() => {
          const newArray = portfolioNodes.filter(
            (items) =>
              items.equityDebt === "Debt Investments" ||
              items.equityDebt === "Both"
          );
          return newArray;
        });
      } else if (selectedIndex === 2) {
        setFilteredArray(() => {
          const newArray = portfolioNodes.filter(
            (items) =>
              items.equityDebt === "Equity Investments" ||
              items.equityDebt === "Both"
          );
          return newArray;
        });
      }
    } else {
      setFilteredArray(() => {
        const newArray = portfolioNodes;
        return newArray;
      });
    }
  }, [selectedInvestment, selectedIndex]);

  const investments = filteredArray.map((item, i) =>
    item.slug ? (
      <Link
        to={`/${item.slug.current}/`}
        className="rounded-xl bg-white px-6 no-underline shadow-5xl"
        key={i}
      >
        <div className="relative h-full pb-7 pt-8 md:pb-18">
          {item.logo && (
            <div className="mb-8 text-center">
              <GatsbyImage
                image={item.logo.asset.gatsbyImageData}
                alt={item.name}
                loading="lazy"
                className="rounded-[50%]"
              />
            </div>
          )}
          <div className="text-center">
            <div className="mb-5 mr-1 inline-block rounded bg-slate-200 px-2 py-1 text-center text-xs font-semibold uppercase  text-slate-600 last:mr-0">
              {item.industry.title}
            </div>
          </div>

          {item.title && (
            <div className="mb-8 text-center font-heading text-lg font-bold uppercase tracking-wide text-typography-heading">
              {item.title}
            </div>
          )}

          <div className="bottom-8 left-0 flex w-full justify-between md:absolute">
            {item.dateInvested && (
              <div>
                <div className="text-sm font-bold uppercase tracking-wide text-gray-400">
                  Invested
                </div>
                <div className="font-semibold text-typography-heading">
                  {item.dateInvested}
                </div>
              </div>
            )}

            {item.status && (
              <div className="text-right">
                <div className="text-sm font-bold uppercase tracking-wide text-gray-400">
                  Status
                </div>
                <div
                  className={`font-semibold ${
                    item.status === "Active"
                      ? "text-[#005F42]"
                      : "text-[#A20214]"
                  }`}
                >
                  {item.status}
                </div>
              </div>
            )}
          </div>
        </div>
      </Link>
    ) : (
      <div className="rounded-xl bg-white px-6 shadow-5xl" key={i}>
        <div className="relative h-full pb-7 pt-8 md:pb-18">
          {item.logo && (
            <div className="mb-8 text-center">
              <GatsbyImage
                image={item.logo.asset.gatsbyImageData}
                alt={item.name}
                loading="lazy"
              />
            </div>
          )}

          {item.title && (
            <div className="mb-8 text-center font-heading text-lg font-bold uppercase tracking-wide text-typography-heading">
              {item.title}
            </div>
          )}

          <div className="bottom-8 left-0 flex w-full justify-between space-x-4 md:absolute">
            {item.dateInvested && (
              <div>
                <div className="text-sm font-bold uppercase tracking-wide text-gray-400">
                  Invested
                </div>
                <div className="font-semibold text-typography-heading">
                  {item.dateInvested}
                </div>
              </div>
            )}

            {item.status && (
              <div className="text-right">
                <div className="text-sm font-bold uppercase tracking-wide text-gray-400">
                  Status
                </div>
                <div
                  className={`font-semibold ${
                    item.status === "Active"
                      ? "text-[#005F42]"
                      : "text-[#A20214]"
                  }`}
                >
                  {item.status} fund
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  );

  return (
    <Layout>
      <SearchEngineOptimization
        title="Portfolio | Assurance Capital"
        description="Assurance Capital's current investments include businesses in a wide range of industries. Contact us to learn about custom growth solutions for your company."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="bg-primary-500 py-18 text-center md:py-20">
        <div className="container relative">
          <h1 className="text-white">Our Portfolio</h1>
          <p className="mb-0 text-xl text-white/80">
            View our current investments that include businesses from a wide
            range of industries.
          </p>
        </div>
      </section>

      <section className="bg-primary-50 pb-20 pt-10 md:pb-32 md:pt-18">
        <div className="container">
          <div className="mb-10 md:mb-18">
            <div className="md:hidden">
              <Listbox
                value={selectedInvestment}
                onChange={setSelectedInvestment}
              >
                <div className="relative block">
                  <Listbox.Button
                    className={({ open }) =>
                      `relative flex w-full cursor-pointer items-center justify-between rounded bg-white px-4 py-3 text-left text-base font-semibold text-gray-600 no-underline shadow-5xl focus:outline-none md:w-[173px] md:py-3 ${
                        open && "shadow-7xl"
                      }`
                    }
                  >
                    <span className="block truncate">
                      {selectedInvestment.title}
                    </span>
                    <i className="fas fa-chevron-down ml-2"></i>
                  </Listbox.Button>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-2 max-h-80 w-full overflow-auto rounded bg-white py-1.5 text-sm shadow-5xl focus:outline-none">
                      {investmentNodes.map((investment, i) => (
                        <Listbox.Option
                          key={i}
                          className={({ active }) =>
                            `relative cursor-default select-none px-4 py-1.5 font-semibold text-gray-900 ${
                              active && "bg-primary-50"
                            }`
                          }
                          value={investment}
                        >
                          {({ selected }) => (
                            <div className="flex items-center justify-between">
                              <span
                                className={`block ${
                                  selected && "font-semibold"
                                }`}
                              >
                                {investment.title}
                              </span>
                              {selected ? (
                                <i className="fa-solid fa-check text-lg text-primary-500"></i>
                              ) : null}
                            </div>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </Listbox>
            </div>

            <div className="hidden md:block">
              <Tab.Group
                selectedIndex={selectedIndex}
                onChange={setSelectedIndex}
              >
                <Tab.List className="relative mx-auto flex max-w-[607px] justify-between space-x-10 border-b border-gray-300 pb-5">
                  {investmentNodes.map((investment, i) => (
                    <Tab
                      key={i}
                      className={({ selected }) =>
                        `font-normal no-underline ${
                          selected ? "text-primary-500" : "text-primary-500/50"
                        }`
                      }
                      value={investment}
                    >
                      {investment.title}
                    </Tab>
                  ))}
                  <div
                    className={`absolute bottom-0 h-1 bg-primary-500 transition-all duration-300 ease-linear ${
                      selectedIndex === 0
                        ? "left-[calc(0-2.5rem)] w-[calc(33.33%-2.5rem)]"
                        : selectedIndex === 1
                        ? "left-[calc(33.33%-2.5rem)] w-[calc(33.33%-2.5rem)]"
                        : selectedIndex === 2
                        ? "left-[calc(66.66%-2.5rem)] w-[33.33%]"
                        : "left-0"
                    }`}
                  ></div>
                </Tab.List>
              </Tab.Group>
            </div>
          </div>

          <div className="grid gap-8 md:grid-cols-3 md:gap-10 lg:gap-16">
            {investments}
          </div>
        </div>
      </section>

      <CallToAction />
    </Layout>
  );
};

export default Page;
